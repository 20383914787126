<template>
  <div>
    <slot v-if="renderLab" />
    <not-compatible-browser v-if="showDialog" :error-type="errorType" @close="showDialog = false" />
  </div>
</template>

<script>
import Vue from 'vue';
import Bowser from 'bowser';
import NotCompatibleBrowser from '@/tasks/components/NotCompatibleBrowser.vue';
import {SimWrapperError} from '@/tasks/types/SimWrapperError';

export default Vue.extend({
  name: 'SimWrapper',
  components: {NotCompatibleBrowser},
  props: {
    acceptedBrowserNames: {
      type: Array,
      default: () => ['chrome', 'firefox', 'microsoft edge'],
    },
    acceptedPlatformTypes: {
      type: Array,
      default: () => ['desktop'],
    },
    userAgentSpecification: {
      type: Object,
      default: null,
    },
    shouldCheckBrowser: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showDialog: false,
      errorType: '',
      renderLab: false,
    };
  },
  beforeMount() {
    if (!this.shouldCheckBrowser) {
      this.renderLab = true;
      return;
    }

    const parser = Bowser.getParser(window.navigator.userAgent);
    const browserName = parser.getBrowserName().toLowerCase();
    const platformType = parser.getPlatformType().toLowerCase();

    if (parser.getOSName().toLowerCase() === 'chrome os') {
      this.renderLab = true;
      return;
    }

    if (this.userAgentSpecification !== null) {
      if (!parser.satisfies(this.userAgentSpecification)) {
        this.errorType = SimWrapperError.InvalidBrowser;
        this.showDialog = true;
        return;
      }
    } else {
      if (!this.acceptedBrowserNames.includes(browserName.toLowerCase())) {
        this.errorType = SimWrapperError.InvalidBrowser;
        this.showDialog = true;
        return;
      }

      if (!this.acceptedPlatformTypes.includes(platformType.toLowerCase())) {
        this.errorType = SimWrapperError.InvalidPlatform;
        this.showDialog = true;
        return;
      }
    }

    this.renderLab = true;
  },
});
</script>
