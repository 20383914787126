


















































import {computed, defineComponent, PropType} from '@vue/composition-api';

export default defineComponent({
  name: 'SimPlaceholder',
  props: {
    isResetting: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props: {isResetting: boolean}) {
    const loadingText = computed(() => {
      if (props.isResetting) {
        return 'Resetting Lab';
      }
      return 'Virtual Lab Loading';
    });

    return {loadingText};
  },
});
