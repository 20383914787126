<template>
  <div>
    <slot name="heading"></slot>
    <div class="sim-container">
      <sim-placeholder v-if="!startSim">
        <button class="sim-button" @click="startSim = true">
          <v-icon color="white">mdi-play</v-icon>
          {{ $t('simulations.start') }}
        </button>
      </sim-placeholder>
      <div v-else class="lab">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, ref} from '@vue/composition-api';
import i18n from '@/locales/composables/i18n';
import {frSimulations} from '@/locales/fr/simulationsFr';
import {simulations} from '@/locales/en/simulations';
import SimPlaceholder from '@/loading/components/SimPlaceholder.vue';

export default defineComponent({
  name: 'LabTask',
  components: {SimPlaceholder},
  setup() {
    const startSim = ref(false);

    i18n.mergeLocaleMessage('en', simulations);
    i18n.mergeLocaleMessage('fr', frSimulations);

    return {startSim};
  },
});
</script>
<style scoped>
.sim-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sim-container .sim-placeholder .sim-button {
  background-color: #2b65ac;
  color: rgb(255 255 255);
  line-height: 1.25rem;
  font-weight: 500;
  border-radius: 9999px;
  padding: 0.75rem 1.75rem 0.75rem 1.5rem;
  font-size: 1rem;
  box-shadow:
    1px 3px 10px 0px rgba(27, 19, 53, 0.09),
    0px 1px 2px 0px rgba(27, 19, 53, 0.07),
    8px 8px 16px 0px rgba(83, 177, 221, 0.2) inset,
    -8px -8px 16px 0px rgba(37, 48, 114, 0.2) inset;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  transition: all 0.1s ease-out;
}

.sim-container .sim-placeholder .sim-button:hover {
  background-color: #2c7cbe;
}

.sim-placeholder .logo {
  min-width: 18rem;
  width: 50%;
  max-width: 26rem;
  height: auto;
  flex: 0 0 auto;
}

.sim-placeholder .logo svg {
  width: 100%;
  height: auto;
}

.sim-placeholder {
  position: relative;
  z-index: 2;
  aspect-ratio: 17 / 10;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  background: radial-gradient(farthest-corner at 50% 30%, #120b28, black 120%);
  border-radius: 0.25rem;
}

.lab {
  aspect-ratio: 17 / 10;
  width: 100%;
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
