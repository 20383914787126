













































import {SimWrapperError} from '@/tasks/types/SimWrapperError';
import {defineComponent, PropType} from '@vue/composition-api';

export default defineComponent({
  name: 'NotCompatibleBrowserDialog',
  props: {
    errorType: {
      type: String as PropType<SimWrapperError>,
      required: true,
    },
  },
  data: () => ({
    SimWrapperError,
  }),
  methods: {
    cancel() {
      this.$emit('close');
    },
  },
});
